import React from 'react'
import { Outlet, Link } from "react-router-dom";

const Header = () => {
  return (
    <>
        <header className="site-header header-style-3 mobile-sider-drawer-menu"> 
            <div className="sticky-header main-bar-wraper  navbar-expand-lg">
                <div className="main-bar">             
                    <div className="container-fluid clearfix"> 
                        <div className="logo-header">
                            <div className="logo-header-inner logo-header-one">
                                <Link to="/">
                                    <img src="assets/images/logo.png" alt="img" width="120px" />
                                </Link>
                            </div>
                        </div>  
                        {/* NAV Toggle Button */}
                        <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggler collapsed">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar icon-bar-first" />
                            <span className="icon-bar icon-bar-two" />
                            <span className="icon-bar icon-bar-three" />
                        </button> 
                        {/* MAIN Vav */}
                        <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                            <ul className=" nav navbar-nav">  
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/drone-insurance">Drone Insurance</Link></li>
                                <li className="has-child"><Link to="/buy-drone">Drone Store</Link>
                                    <ul className="sub-menu">
                                        <li><Link to="/buy-drone">Buy Drones</Link></li>                                        
                                        <li><Link to="/buy-drone-parts">Buy Drone Parts</Link></li>     
                                    </ul>   
                                </li>
                                <li><Link to="/drone-loans">Drone Loans</Link></li> 
                                <li className="has-child"><Link to="/drone-jobs">Drone Jobs & Hire Pilots</Link>
                                    <ul className="sub-menu">
                                        <li><Link to="/drone-jobs">Find Drone Jobs</Link></li>                                        
                                        <li><Link to="/drone-tenders">Explore Drone Tenders</Link></li>
                                        <li><Link to="/drone-pilots">Hire Drone Pilots</Link></li>                                       
                                    </ul>                                
                                </li>
                            </ul>
                        </div>
                        {/* Header Right Section*/}
                                                
                    </div>     
                </div> 
            </div> 
        </header>
        <Outlet />
    </>
  )
}

export default Header