import React from 'react'
import { Outlet, Link } from "react-router-dom";

const Breadcrumb = (props) => {
  return (
    <>
       {/* INNER PAGE BANNER */}
        <div className="wt-bnr-inr overlay-wraper bg-center" style={{backgroundImage: 'url(assets/images/banner/1.jpg)'}}>
            <div className="overlay-main site-bg-white opacity-01" />
                <div className="container">
                    <div className="wt-bnr-inr-entry">
                        <div className="banner-title-outer">
                            <div className="banner-title-name">
                                <h2 className="wt-title">{props.title}</h2>
                            </div>
                        </div>
                    {/* BREADCRUMB ROW */}                            
                    <div>
                        <ul className="wt-breadcrumb breadcrumb-style-2">
                            <li><Link to="/">Home</Link></li>
                            <li>{props.subtitle}</li>
                        </ul>
                    </div>
                    {/* BREADCRUMB ROW END */}                        
                </div>
            </div>
        </div>
        {/* INNER PAGE BANNER END */}
        <Outlet/>
    </>
  )
}

export default Breadcrumb