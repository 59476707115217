import React from 'react'
import Breadcrumb from '../../common/Breadcrumb' 
import { Link } from 'react-router-dom' 
import { useForm } from 'react-hook-form';

const DroneInsurance = () => { 
    const { register, formState: { errors } } = useForm();  // validation for login form    
  return (
    <>
      <div className="page-content">
          <Breadcrumb title="Drone Insurance" subtitle="Drone Insurance" />

          {/* Employer Account START */}
          <div className="section-full p-t120  p-b90 site-bg-white bg-cover twm-ac-fresher-wrap" style={{backgroundImage: 'url(assets/images/background/pattern.jpg)'}}>
            <span className="twm-section-bg-img">
              <img src="assets/images/reg-bg2.png" alt="favara" />
            </span>
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-8 col-md-12">
                  <div className="twm-right-section-panel-wrap2">
                    {/*Filter Short By*/}
                    <div className="twm-right-section-panel site-bg-primary">
                      {/*Basic Information*/}
                      <div className="panel panel-default">
                        <div className="panel-heading wt-panel-heading p-a20">
                          <h4 className="panel-tittle m-a0">Applying for Drone Insurance</h4>
                        </div>
                        <div className="panel-body wt-panel-body p-a20 ">
                          <form method="post" noValidate autoComplete="off" encType='multipart/form-data'>
                            <div className="row">  
                              <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="form-group">
                                  <label htmlFor='company_name'>Company Name</label>
                                  <div className="ls-inputicon-box"> 
                                    <input className="form-control" name="company_name" id='company_name' type="text" 
                                    tabIndex={2}
                                    {...register("company_name", {
                                        required: true
                                    })} />
                                    <i className="fs-input-icon fas fa-at" />
                                  </div>
                                  {errors.company_name && errors.company_name.type === "required" && (
                                      <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                  )}
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="form-group">
                                  <label htmlFor='job_location'>Job Location</label>
                                  <div className="ls-inputicon-box"> 
                                    <input className="form-control" name="job_location" id='job_location' type="text" 
                                    tabIndex={3}
                                    {...register("job_location", {
                                        required: true
                                    })} />
                                    <i className="fs-input-icon fas fa-map-pin" />
                                  </div>
                                  {errors.job_location && errors.job_location.type === "required" && (
                                      <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                  )}
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="form-group">
                                  <label htmlFor='email'>Email</label>
                                  <div className="ls-inputicon-box"> 
                                    <input className="form-control" name="email" id='email' type="email"  
                                     tabIndex={4}
                                     {...register('email', {
                                          required: true,
                                          pattern: /\S+@\S+\.\S+/
                                      })} />
                                    <i className="fs-input-icon fas fa-at" />
                                  </div>
                                  {errors.email && errors.email.type === "required" && (
                                      <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                  )}
                                  {errors.email && errors.email.type === "pattern" && (
                                      <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">Enter valid email!</span>
                                  )}
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="form-group">
                                  <label htmlFor='phone'>Phone Number</label>
                                  <div className="ls-inputicon-box"> 
                                    <input className="form-control" name="phone" id='phone' type="text" 
                                    tabIndex={5}
                                    {...register("phone", {
                                        required: true,
                                        minLength: 10,
                                        pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                                    })} />
                                    <i className="fs-input-icon fa fa-phone-alt" />
                                  </div>
                                  {errors.phone && errors.phone.type === "required" && (
                                      <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                  )}
                                  {errors.phone && errors.phone.type === "minLength" && (
                                      <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">Enter minimum 10 digit!</span>
                                  )}
                                  {errors.phone && errors.phone.type === "pattern" && (
                                      <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">Enter valid phone number!</span>
                                  )}
                                </div>
                              </div> 
                              <div className="col-xl-8 col-lg-8 col-md-12">
                                <div className="form-group">
                                  <label htmlFor='job_title'>Job Title</label>
                                  <div className="ls-inputicon-box"> 
                                    <input className="form-control" name="job_title" id='job_title' type="text"  
                                    tabIndex={6}
                                    {...register("job_title", {
                                        required: true
                                    })} />
                                    <i className="fs-input-icon fa fa-pen" />
                                  </div>
                                  {errors.job_title && errors.job_title.type === "required" && (
                                      <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                  )}
                                </div>
                              </div>
                              <div className="col-xl-4 col-lg-4 col-md-12">
                                <div className="form-group">
                                  <label htmlFor='job_type'>Job Type</label>
                                  <div className="ls-inputicon-box"> 
                                    <select className="form-control" name="job_type" id='job_type' tabIndex={7}
                                     {...register("job_type", {
                                         required: true
                                     })} > 
                                      <option>Full Time</option>
                                      <option>Part Time</option>
                                      <option>Contract</option>
                                      <option>Not Available</option>
                                    </select>
                                    <i className="fs-input-icon fa fa-list" />
                                  </div>
                                  {errors.job_type && errors.job_type.type === "required" && (
                                      <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                  )}
                                </div>
                              </div> 

                              <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label htmlFor='job_heading'>Job Heading</label>
                                  <div className="ls-inputicon-box"> 
                                    <input className="form-control" name="job_heading" id='job_heading' type="text" 
                                    tabIndex={8}
                                    {...register("job_heading", {
                                        required: true
                                    })} />
                                    <i className="fs-input-icon fa fa-pen" />
                                  </div>
                                  {errors.job_heading && errors.job_heading.type === "required" && (
                                      <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                  )}
                                </div>
                              </div>  
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label htmlFor='job_description'>Job Description</label>
                                  <div className="ls-inputicon-box"> 
                                    <textarea className="form-control" name="job_description" id='job_description'  tabIndex={9}
                                     {...register("job_description", {
                                         required: true
                                     })} ></textarea>
                                    <i className="fs-input-icon fa fa-info" />
                                  </div>
                                  {errors.job_description && errors.job_description.type === "required" && (
                                      <span className='alert p-1 mt-2 font-12 alert-warning' role="alert">This is required field!</span>
                                  )}
                                </div>
                              </div>  

                              <div className="col-xl-12 col-lg-12">
                                <div className="form-group">
                                  <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault2" defaultChecked /> 
                                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                                      &nbsp; I agree to the <Link to="https://favara.in/terms" target='_blank'>Terms and Conditions</Link> and <Link to="https://favara.in/policy" target='_blank'>Privacy Policy</Link>.
                                  </label>
                                </div>
                              </div>
                              <div className="col-xl-12 col-lg-12 col-md-12">                                  
                                <div className="text-left">
                                  <button className="site-button">Submit</button>
                                </div>
                              
                              </div>                                         
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>   
          {/* Employer Account START END */}

      </div>
    </>
  )
}

export default DroneInsurance