import React from "react"; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = () => {
  return (
    <> 
     <div className="page-content">

        {/*Banner Start*/}
        <div className="twm-home1-banner-section site-bg-gray bg-cover" style={{backgroundImage: 'url(assets/images/main-slider/slider1/bg1.jpg)'}}>
          <div className="row">
            {/*Left Section*/}
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="twm-bnr-left-section">
                <div className="twm-bnr-title-small">We Have <span className="site-text-primary">208,000+</span> Live Jobs</div>
                <div className="twm-bnr-title-large">What can we   <span className="site-text-primary">help</span> you today with?</div>
                <div className="twm-bnr-discription">Specialized loans for Indian made Agricultural, Survey & Training Drones.</div> 
              </div>
            </div>
            {/*right Section*/}
            <div className="col-xl-6 col-lg-6 col-md-12 twm-bnr-right-section">
              <div className="twm-bnr-right-content">
                <div className="twm-img-bg-circle-area">
                  <div className="twm-img-bg-circle1 rotate-center"><span /></div>
                  <div className="twm-img-bg-circle2 rotate-center-reverse"><span /></div>
                  <div className="twm-img-bg-circle3"><span /></div>
                </div>
                <div className="twm-bnr-right-carousel"> 
                  <div className="slide-img">
                    <img src="assets/images/main-slider/slider1/r-img1.png" alt="favara" />
                  </div>
                  <div className="twm-bnr-blocks-position-wrap">
                    {/*icon-block-1*/}
                    <div className="twm-bnr-blocks twm-bnr-blocks-position-1">
                      <div className="twm-icon">
                        <img src="assets/images/main-slider/slider1/icon-1.png" alt="favara" />
                      </div>
                      <div className="twm-content">
                        <div className="tw-count-number text-clr-sky">
                          <span className="counter">12</span>K+
                        </div>
                        <p className="icon-content-info">Companies Jobs</p>
                      </div>
                    </div>
                    {/*icon-block-2*/}
                    <div className="twm-bnr-blocks twm-bnr-blocks-position-2">
                      <div className="twm-icon">
                        <img src="assets/images/main-slider/slider1/icon-2.png" alt="favara" />
                      </div>
                      <div className="twm-content">
                        <div className="tw-count-number text-clr-pink">
                          <span className="counter">98</span> +
                        </div>
                        <p className="icon-content-info">Job For Countries </p>
                      </div>
                    </div>
                    {/*icon-block-3*/} 
                  </div>
                </div>
                {/*Samll Ring Left*/}
                <div className="twm-small-ring-l slide-top-animation" />
                <div className="twm-small-ring-2 slide-top-animation" />
              </div>
            </div>
          </div>
          <div className="twm-gradient-text">
            Favara
          </div>
        </div>
        {/*Banner End*/} 

        {/* JOBS CATEGORIES SECTION START */}
        <div className="section-full p-t120 p-b90 site-bg-gray twm-job-categories-area">
          <div className="container">
            <div className="wt-separator-two-part">
              <div className="row wt-separator-two-part-row">
                <div className="col-xl-5 col-lg-5 col-md-12 wt-separator-two-part-left">
                  {/* TITLE START*/}
                  <div className="section-head left wt-small-separator-outer">
                    <div className="wt-small-separator site-text-primary">
                      <div>Jobs by Categories</div>                                
                    </div>
                    <h2 className="wt-title">Choose Your Desire Category</h2>
                  </div>                  
                  {/* TITLE END*/}
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the  when an printer took.</p>
                </div>
              </div>
            </div>
            <div className="twm-job-categories-section">
              <div className="job-categories-style1 m-b30">
                <OwlCarousel className="owl-theme job-categories-carousel owl-btn-left-bottom " loop margin={30} nav dots={false}>
                  {/* COLUMNS 1 */} 
                  <div className="item ">
                    <div className="job-categories-block">
                      <div className="twm-media">
                        <div className="flaticon-dashboard" />
                      </div>                                   
                      <div className="twm-content">
                        <div className="twm-jobs-available">9,185 Jobs</div>
                        <a href="job-detail.html">Business Development</a>
                      </div>                               
                    </div>
                  </div>
                  {/* COLUMNS 2 */} 
                  <div className="item ">
                    <div className="job-categories-block">
                      <div className="twm-media">
                        <div className="flaticon-project-management" />
                      </div>                                   
                      <div className="twm-content">
                        <div className="twm-jobs-available">3,205 Jobs</div>
                        <a href="job-detail.html">Project Management</a>
                      </div>                               
                    </div>
                  </div>
                  {/* COLUMNS 3 */} 
                  <div className="item ">
                    <div className="job-categories-block">
                      <div className="twm-media">
                        <div className="flaticon-note" />
                      </div>                                   
                      <div className="twm-content">
                        <div className="twm-jobs-available">2,100 Jobs</div>
                        <a href="job-detail.html">Content Writer</a>
                      </div>                               
                    </div>
                  </div>
                  {/* COLUMNS 4 */} 
                  <div className="item ">
                    <div className="job-categories-block">
                      <div className="twm-media">
                        <div className="flaticon-customer-support" />
                      </div>                                   
                      <div className="twm-content">
                        <div className="twm-jobs-available">1,500 Jobs</div>
                        <a href="job-detail.html">Costomer Services</a>
                      </div>                               
                    </div>
                  </div>
                  {/* COLUMNS 5 */} 
                  <div className="item ">
                    <div className="job-categories-block">
                      <div className="twm-media">
                        <div className="flaticon-bars" />
                      </div>                                   
                      <div className="twm-content">
                        <div className="twm-jobs-available">9,185 Jobs</div>
                        <a href="job-detail.html">Accounting / Finance</a>
                      </div>                               
                    </div>
                  </div>
                  {/* COLUMNS 6 */} 
                  <div className="item ">
                    <div className="job-categories-block">
                      <div className="twm-media">
                        <div className="flaticon-user" />
                      </div>                                   
                      <div className="twm-content">
                        <div className="twm-jobs-available">3,205 Jobs</div>
                        <a href="job-detail.html">Marketing</a>
                      </div>                               
                    </div>
                  </div> 
                </OwlCarousel>
              </div>

              <div className="text-right job-categories-btn">
                <a href="job-grid.html" className=" site-button">All Categories</a>
              </div>
            </div>
          </div>
        </div>   
        {/* JOBS CATEGORIES SECTION END */}

        {/* EXPLORE NEW LIFE START */}
        <div className="section-full p-t120 p-b120 twm-explore-area bg-cover " style={{backgroundImage: 'url(assets/images/background/bg-1.jpg)'}}>
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="twm-explore-media-wrap">
                    <div className="twm-media">
                      <img src="assets/images/gir-large.png" alt="favara" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="twm-explore-content-outer">
                    <div className="twm-explore-content">
                      <div className="twm-l-line-1" />
                      <div className="twm-l-line-2" />
                      <div className="twm-r-circle-1" />
                      <div className="twm-r-circle-2" />
                      <div className="twm-title-small">Explore New Life</div>
                      <div className="twm-title-large">
                        <h2>Don’t just find. be found 
                          put your CV in front of 
                          great employers </h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the  when an printer took.</p>
                      </div>
                      <div className="twm-upload-file">
                        <button type="button" className="site-button">Upload Your Resume <i className="feather-upload" /></button>
                      </div>
                    </div>
                    <div className="twm-bold-circle-right" />
                    <div className="twm-bold-circle-left" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* EXPLORE NEW LIFE END */}

        {/* TOP COMPANIES START */}
        <div className="section-full p-t120  site-bg-white twm-companies-wrap">
          {/* TITLE START*/}
          <div className="section-head center wt-small-separator-outer">
            <div className="wt-small-separator site-text-primary">
              <div>Top Companies</div>                                
            </div>
            <h2 className="wt-title">Get hired in top companies</h2>
          </div>                  
          {/* TITLE END*/}
          <div className="container">
            <div className="section-content">
              <div className="owl-carousel home-client-carousel2 owl-btn-vertical-center">
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <a href="employer-list.html"><img src="assets/images/client-logo/w1.png" alt="favara" /></a></div>
                  </div>
                </div>
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <a href="employer-list.html"><img src="assets/images/client-logo/w2.png" alt="favara" /></a></div>
                  </div>
                </div>
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <a href="employer-list.html"><img src="assets/images/client-logo/w3.png" alt="favara" /></a></div>
                  </div>
                </div>
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <a href="employer-list.html"><img src="assets/images/client-logo/w4.png" alt="favara" /></a></div>
                  </div>
                </div>
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <a href="employer-list.html"><img src="assets/images/client-logo/w5.png" alt="favara" /></a></div>
                  </div>
                </div>
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <a href="employer-list.html"><img src="assets/images/client-logo/w6.png" alt="favara" /></a></div>
                  </div>
                </div>
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <a href="employer-list.html"><img src="assets/images/client-logo/w1.png" alt="favara" /></a></div>
                  </div>
                </div>
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <a href="employer-list.html"><img src="assets/images/client-logo/w2.png" alt="favara" /></a></div>
                  </div>
                </div>
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <a href="employer-list.html"><img src="assets/images/client-logo/w3.png" alt="favara" /></a></div>
                  </div>
                </div>
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <a href="employer-list.html"><img src="assets/images/client-logo/w5.png" alt="favara" /></a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="twm-company-approch-outer">
            <div className="twm-company-approch">
              <div className="row">
                {/*block 1*/}
                <div className="col-lg-4 col-md-12">
                  <div className="counter-outer-two">
                    <div className="icon-content">
                      <div className="tw-count-number text-clr-sky">
                        <span className="counter">5</span>M+</div>
                      <p className="icon-content-info">Million daily active users</p>
                    </div>
                  </div>
                </div>
                {/*block 2*/}
                <div className="col-lg-4 col-md-12">
                  <div className="counter-outer-two">
                    <div className="icon-content">
                      <div className="tw-count-number text-clr-pink">
                        <span className="counter">9</span>K+</div>
                      <p className="icon-content-info">Open job positions</p>
                    </div>
                  </div>
                </div>
                {/*block 3*/}
                <div className="col-lg-4 col-md-12">
                  <div className="counter-outer-two">
                    <div className="icon-content">
                      <div className="tw-count-number text-clr-green">
                        <span className="counter">2</span>M+</div>
                      <p className="icon-content-info">Million stories shared</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* TOP COMPANIES END */}

        {/* JOB POST START */}
        <div className="section-full p-t120 p-b90 site-bg-light-purple twm-bg-ring-wrap">
          <div className="twm-bg-ring-right" />
          <div className="twm-bg-ring-left" />
          <div className="container">
            {/* TITLE START*/}
            <div className="section-head center wt-small-separator-outer">
              <div className="wt-small-separator site-text-primary">
                <div>All Jobs Post</div>                                
              </div>
              <h2 className="wt-title">Find Your Career You Deserve it</h2>
            </div>                  
            {/* TITLE END*/}
            <div className="section-content">
              <div className="twm-jobs-list-wrap">
                <ul>
                  <li>
                    <div className="twm-jobs-list-style1 mb-5">
                      <div className="twm-media">
                        <img src="assets/images/jobs-company/pic1.jpg" alt="favara" />
                      </div>
                      <div className="twm-mid-content">
                        <a href="job-detail.html" className="twm-job-title">
                          <h4>Senior Web Designer , Developer  <span className="twm-job-post-duration">/ 1 days ago</span></h4>
                        </a>
                        <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                        <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                      </div>
                      <div className="twm-right-content">
                        <div className="twm-jobs-category green"><span className="twm-bg-green">New</span></div>
                        <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                        <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Job</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="twm-jobs-list-style1 mb-5">
                      <div className="twm-media">
                        <img src="assets/images/jobs-company/pic2.jpg" alt="favara" />
                      </div>
                      <div className="twm-mid-content">
                        <a href="job-detail.html" className="twm-job-title">
                          <h4>Need Senior Rolling Stock Technician<span className="twm-job-post-duration">/ 15 days ago</span></h4>
                        </a>
                        <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                        <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                      </div>
                      <div className="twm-right-content">
                        <div className="twm-jobs-category green"><span className="twm-bg-brown">Intership</span></div>
                        <div className="twm-jobs-amount">$2000<span>/ Month</span></div>
                        <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Job</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="twm-jobs-list-style1 mb-5">
                      <div className="twm-media">
                        <img src="assets/images/jobs-company/pic3.jpg" alt="favara" />
                      </div>
                      <div className="twm-mid-content">
                        <a href="job-detail.html" className="twm-job-title">
                          <h4 className="twm-job-title">IT Department Manager &amp; Blogger-Entrepenour  <span className="twm-job-post-duration">/ 6 Month ago</span></h4>
                        </a>
                        <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                        <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                      </div>
                      <div className="twm-right-content">
                        <div className="twm-jobs-category green"><span className="twm-bg-purple">Fulltime</span></div>
                        <div className="twm-jobs-amount">$1500 <span>/ Month</span></div>
                        <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Job</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="twm-jobs-list-style1 mb-5">
                      <div className="twm-media">
                        <img src="assets/images/jobs-company/pic4.jpg" alt="favara" />
                      </div>
                      <div className="twm-mid-content">
                        <a href="job-detail.html" className="twm-job-title">
                          <h4 className="twm-job-title">Art Production Specialist   <span className="twm-job-post-duration">/ 2 days ago</span></h4>
                        </a>
                        <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                        <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                      </div>
                      <div className="twm-right-content">
                        <div className="twm-jobs-category green"><span className="twm-bg-sky">Freelancer</span></div>
                        <div className="twm-jobs-amount">$1200<span>/ Month</span></div>
                        <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Job</a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="twm-jobs-list-style1 mb-5">
                      <div className="twm-media">
                        <img src="assets/images/jobs-company/pic5.jpg" alt="favara" />
                      </div>
                      <div className="twm-mid-content">
                        <a href="job-detail.html" className="twm-job-title">
                          <h4 className="twm-job-title">Recreation &amp; Fitness Worker   <span className="twm-job-post-duration">/ 1 days ago</span></h4>
                        </a>
                        <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                        <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                      </div>
                      <div className="twm-right-content">
                        <div className="twm-jobs-category green"><span className="twm-bg-golden">Temporary</span></div>
                        <div className="twm-jobs-amount">$1700 <span>/ Month</span></div>
                        <a href="job-detail.html" className="twm-jobs-browse site-text-primary">Browse Job</a>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="text-center m-b30">
                  <a href="job-list.html" className=" site-button">Browse All Jobs</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* JOB POST END */}

        {/* TESTIMONIAL SECTION START */}
        <div className="section-full p-t120 p-b90 site-bg-white twm-testimonial-1-area">
          <div className="container">
            <div className="wt-separator-two-part">
              <div className="row wt-separator-two-part-row">
                <div className="col-xl-5 col-lg-6 col-md-12 wt-separator-two-part-left">
                  {/* TITLE START*/}
                  <div className="section-head left wt-small-separator-outer">
                    <div className="wt-small-separator site-text-primary">
                      <div>Clients Testimonials</div>                                
                    </div>
                    <h2 className="wt-title">What Our Customers Say About Us</h2>
                  </div>                  
                  {/* TITLE END*/}
                </div>
              </div>
            </div>
            <div className="section-content"> 
              <div className="owl-carousel twm-testimonial-1-carousel owl-btn-bottom-center ">
                {/* COLUMNS 1 */} 
                <div className="item ">
                  <div className="twm-testimonial-1">
                    <div className="twm-testimonial-1-content">
                      <div className="twm-testi-media">
                        <img src="assets/images/testimonials/pic-1.png" alt="favara" />
                      </div>
                      <div className="twm-testi-content">
                        <div className="twm-quote">
                          <img src="assets/images/quote-dark.png" alt="favara" />
                        </div>
                        <div className="twm-testi-info">I just got a job that I applied for via careerfy! I used the site all the time during my job hunt.</div>
                        <div className="twm-testi-detail">
                          <div className="twm-testi-name">Nikola Tesla</div>
                          <div className="twm-testi-position">Accountant</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* COLUMNS 2 */} 
                <div className="item ">
                  <div className="twm-testimonial-1">
                    <div className="twm-testimonial-1-content">
                      <div className="twm-testi-media">
                        <img src="assets/images/testimonials/pic-2.png" alt="favara" />
                      </div>
                      <div className="twm-testi-content">
                        <div className="twm-quote">
                          <img src="assets/images/quote-dark.png" alt="favara" />
                        </div>
                        <div className="twm-testi-info">I just got a job that I applied for via careerfy! I used the site all the time during my job hunt.</div>
                        <div className="twm-testi-detail">
                          <div className="twm-testi-name">Nikola Tesla</div>
                          <div className="twm-testi-position">Accountant</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* COLUMNS 3 */} 
                <div className="item ">
                  <div className="twm-testimonial-1">
                    <div className="twm-testimonial-1-content">
                      <div className="twm-testi-media">
                        <img src="assets/images/testimonials/pic-3.png" alt="favara" />
                      </div>
                      <div className="twm-testi-content">
                        <div className="twm-quote">
                          <img src="assets/images/quote-dark.png" alt="favara" />
                        </div>
                        <div className="twm-testi-info">I just got a job that I applied for via careerfy! I used the site all the time during my job hunt.</div>
                        <div className="twm-testi-detail">
                          <div className="twm-testi-name">Nikola Tesla</div>
                          <div className="twm-testi-position">Accountant</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* COLUMNS 4 */} 
                <div className="item ">
                  <div className="twm-testimonial-1">
                    <div className="twm-testimonial-1-content">
                      <div className="twm-testi-media">
                        <img src="assets/images/testimonials/pic-2.png" alt="favara" />
                      </div>
                      <div className="twm-testi-content">
                        <div className="twm-quote">
                          <img src="assets/images/quote-dark.png" alt="favara" />
                        </div>
                        <div className="twm-testi-info">I just got a job that I applied for via careerfy! I used the site all the time during my job hunt.</div>
                        <div className="twm-testi-detail">
                          <div className="twm-testi-name">Nikola Tesla</div>
                          <div className="twm-testi-position">Accountant</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* COLUMNS 5 */} 
                <div className="item ">
                  <div className="twm-testimonial-1">
                    <div className="twm-testimonial-1-content">
                      <div className="twm-testi-media">
                        <img src="assets/images/testimonials/pic-1.png" alt="favara" />
                      </div>
                      <div className="twm-testi-content">
                        <div className="twm-quote">
                          <img src="assets/images/quote-dark.png" alt="favara" />
                        </div>
                        <div className="twm-testi-info">I just got a job that I applied for via careerfy! I used the site all the time during my job hunt.</div>
                        <div className="twm-testi-detail">
                          <div className="twm-testi-name">Nikola Tesla</div>
                          <div className="twm-testi-position">Accountant</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>                              
          </div>
        </div>
        {/* TESTIMONIAL SECTION END */}
      
      </div>

    </>
  );
};

export default Home;
