import React, { useState } from "react";
import PilotContext from "./pilotContext";
  
const PilotState = (props) => {
    const [allPilot, pilotData] = useState([])
    const [loading, setLoading] = useState(false);
    const host = process.env.REACT_APP_DB_HOST
    const token = localStorage.getItem('token');

    // Add Pilot Record
    const addPilotRecord = async (category,company_name,job_location,email,phone,job_title,job_type,job_heading,job_description,company_logo) => {
        try {
            // ** Do not pass json content type while uploading file in api
            const payload = new FormData();
            payload.append('category', category);
            payload.append('company_name', company_name);
            payload.append('job_location', job_location);
            payload.append('email', email);
            payload.append('phone', phone);
            payload.append('job_title', job_title);
            payload.append('job_type', job_type);
            payload.append('job_heading', job_heading);
            payload.append('job_description', job_description);
            payload.append('company_logo', company_logo);

            const response = await fetch(`${host}/api/pilot/add/`, {
                method: 'POST',
                headers: {
                    'auth-token': token
                },
                body: payload
            });
            const json = await response.json()
            if (json.success) {   
                getAllPilot()
            } else {
                console.log("Oops!" + json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Update Pilot Record
    const updatePilotRecord = async (id, title, description, meta_title, meta_keyword, meta_description, pilot, page, limit) => {
        try {
            // ** Do not pass json content type while uploading file in api
            const payload = new FormData();
            payload.append('pilot', pilot);
            payload.append('title', title);
            payload.append('description', description);
            payload.append('meta_title', meta_title);
            payload.append('meta_keyword', meta_keyword);
            payload.append('meta_description', meta_description);

            const response = await fetch(`${host}/api/pilot/updatepilot/${id}`, {
                method: 'PUT',
                headers: {
                    'auth-token': token
                },
                body: payload
            });
            const json = await response.json()
            if (json.success) { 
                getAllPilot(page, limit)
            } else {
                console.log("Oops!"+ json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }


    // Get all Pilot data
    const getAllPilot = async (page, limit) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/pilot/show/?page=${page}&limit=${limit}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            const json = await response.json()
            setLoading(false);
            pilotData(json);
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Handle Per Rows Change
    const perRowsChange = async (newPerPage, page) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/pilot/show/?page=${page}&limit=${newPerPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            setLoading(false);
            const json = await response.json()
            pilotData(json);
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Delete a Pilot
    const deletePilot = async (id, page, limit) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/pilot/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            const json = await response.json()
            if (json.success) {
                setLoading(false);
                getAllPilot(page, limit);
            } else {
                console.log("Oops!"+ json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    return (
        <PilotContext.Provider value={{ allPilot, loading, getAllPilot, deletePilot, perRowsChange, addPilotRecord, updatePilotRecord }}>
            {props.children}
        </PilotContext.Provider>
    )
}
export default PilotState;