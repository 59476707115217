import React, { useState } from "react";
import JobContext from "./jobContext";

 

const JobState = (props) => {
    const [allJob, jobData] = useState([])
    const [loading, setLoading] = useState(false);
    const host = process.env.REACT_APP_DB_HOST
    const token = localStorage.getItem('token');

    // Add Job Record
    const addJobRecord = async (company, category, title, description, qualifications, sharelink, job_type,status, page, limit) => {
        try {
            // ** Do not pass json content type while uploading file in api
            const payload = new FormData(); 
            payload.append('company', company);
            payload.append('category', category);
            payload.append('title', title);
            payload.append('description', description);
            payload.append('qualifications', qualifications);
            payload.append('sharelink', sharelink);
            payload.append('job_type', job_type);
            payload.append('status', status); 

            const response = await fetch(`${host}/api/job/add/`, {
                method: 'POST',
                headers: {
                    'auth-token': token
                },
                body: payload
            });
            const json = await response.json()
            if (json.success) { 
                getAllJob(page, limit)
            } else {
                console.log("Oops!"+ json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Update Job Record
    const updateJobRecord = async (id, company, category, title, description, qualifications, sharelink, job_type,status, page, limit) => {
        try {
            // ** Do not pass json content type while uploading file in api
            const payload = new FormData();
            payload.append('company', company);
            payload.append('category', category);
            payload.append('title', title);
            payload.append('description', description);
            payload.append('qualifications', qualifications);
            payload.append('sharelink', sharelink);
            payload.append('job_type', job_type);
            payload.append('status', status); 

            const response = await fetch(`${host}/api/job/updatejob/${id}`, {
                method: 'PUT',
                headers: {
                    'auth-token': token
                },
                body: payload
            });
            const json = await response.json()
            if (json.success) { 
                getAllJob(page, limit)
            } else {
                console.log("Oops!"+ json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }


    // Get all Job data
    const getAllJob = async (page, limit) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/job/showjob/?page=${page}&limit=${limit}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            const json = await response.json()
            setLoading(false);
            jobData(json);
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Handle Per Rows Change
    const perRowsChange = async (newPerPage, page) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/job/showjob/?page=${page}&limit=${newPerPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            setLoading(false);
            const json = await response.json()
            jobData(json);
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Delete a job
    const deleteJob = async (id, page, limit) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/job/deletejob/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            const json = await response.json()
            if (json.success) {
                setLoading(false);
                getAllJob(page, limit);
            } else {
                console.log("Oops!"+ json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    return (
        <JobContext.Provider value={{ allJob, loading, getAllJob, deleteJob, perRowsChange, addJobRecord, updateJobRecord }}>
            {props.children}
        </JobContext.Provider>
    )
}
export default JobState;