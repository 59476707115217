import React from 'react'
import Breadcrumb from '../../../common/Breadcrumb'   

const Drone = () => {  
  return (
    <>
      <div className="page-content">
          <Breadcrumb title="Buy Drone" subtitle="Buy Drone" />
 
          <div className="section-full p-t120  p-b90 site-bg-white bg-cover twm-ac-fresher-wrap" style={{backgroundImage: 'url(assets/images/background/pattern.jpg)'}}> 
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-8 col-md-12">
                  Coming soon...
                </div>
              </div>
            </div>
          </div>    

      </div>
    </>
  )
}

export default Drone