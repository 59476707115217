import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
        {/* FOOTER START */}
            <footer className="footer-dark pt-0" style={{backgroundImage: 'url(assets/images/f-bg.jpg)'}}>
                <div className="container"> 
                    {/* FOOTER BLOCKES START */}  
                    <div className="footer-top">
                        <div className="row">
                            <div className="col-lg-3 col-md-12">
                                <div className="widget widget_about">
                                    <h3 className="widget-title">Contact us</h3>
                                    <p>If you wish to report a breach of the Privacy Policy, you may contact us using the information on the support page.</p>
                                    <ul className="ftr-list">
                                        <li><p><span>Address :</span>808, Suratwala Mark Plazzo Hinjawadi IT Park, Phase 1, Pune 411 057 </p></li>
                                        <li><p><span>Email :</span>support@favara.in, | For Job - hr@favara.in</p></li>
                                        <li><p><span>Call :</span>+91 9511898819</p></li>
                                    </ul>
                                </div>                            
                            </div> 
                            <div className="col-lg-9 col-md-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="widget widget_services ftr-list-center">
                                        <h3 className="widget-title">Stores</h3>
                                        <ul>
                                            <li><Link to="/buy-drone">Buy Drone</Link></li>
                                            <li><Link to="/buy-drone-parts">Drone Parts</Link></li> 
                                        </ul>
                                    </div>
                                </div> 
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="widget widget_services ftr-list-center">
                                        <h3 className="widget-title">Helpful Resources</h3>
                                        <ul>
                                            <li><Link to="/drone-insurance">Drone Insurance</Link></li>
                                            <li><Link to="/drone-loans">Drone Loans</Link></li>
                                            <li><Link to="/drone-jobs">Drone Jobs</Link></li>
                                            <li><Link to="/drone-pilots">Hire a Pilots</Link></li>
                                            <li><Link to="/drone-tenders">Drone Tenders</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">  
                                    <div className="widget widget_services ftr-list-center">
                                        <h3 className="widget-title">Quick Links</h3>
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="https://favara.in/aboutus" target='_blank'>About us</Link></li>
                                            <li><Link to="https://favara.in/contact" target='_blank'>Contact us</Link></li>
                                            <li><Link to="https://favara.in/terms" target='_blank'>Terms Condition</Link></li>
                                            <li><Link to="https://favara.in/policy" target='_blank'>Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>  
                            </div> 
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom">
                        <div className="footer-bottom-info">
                            <div className="footer-copy-right">
                                <span className="copyrights-text">© All rights reserved by Favara.in</span>
                            </div> 
                        </div>
                    </div>
                </div>
            </footer>
        {/* FOOTER END */}

        {/* BUTTON TOP START */}
        <button className="scroltop"><span className="fa fa-angle-up  relative" id="btn-vibrate" /></button>

    </>
  )
}

export default Footer