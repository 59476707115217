import React, { useState } from "react";
import CompanyContext from "./companyContext";

 

const CompanyState = (props) => {
    const [allCompany, companyData] = useState([])
    const [loading, setLoading] = useState(false);
    const host = process.env.REACT_APP_DB_HOST
    const token = localStorage.getItem('token');

    // Add Company Record
    const addCompanyRecord = async (title, email, phone, address, city, company, page, limit) => {
        try {
            // ** Do not pass json content type while uploading file in api
            const payload = new FormData();
            payload.append('company', (company) ? company : '');
            payload.append('title', title);
            payload.append('email', email);
            payload.append('phone', phone);
            payload.append('address', address);
            payload.append('city', city); 

            const response = await fetch(`${host}/api/company/add/`, {
                method: 'POST',
                headers: {
                    'auth-token': token
                },
                body: payload
            });
            const json = await response.json()
            if (json.success) { 
                getAllCompany(page, limit)
            } else {
                console.log("Oops!"+ json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Update Company Record
    const updateCompanyRecord = async (id, title, email, phone, address, city, company, page, limit) => {
        try {
            // ** Do not pass json content type while uploading file in api
            const payload = new FormData();
            payload.append('company', (company) ? company : '');
            payload.append('title', title);
            payload.append('email', email);
            payload.append('phone', phone);
            payload.append('address', address);
            payload.append('city', city); 

            const response = await fetch(`${host}/api/company/updatecompany/${id}`, {
                method: 'PUT',
                headers: {
                    'auth-token': token
                },
                body: payload
            });
            const json = await response.json()
            if (json.success) { 
                getAllCompany(page, limit)
            } else {
                console.log("Oops!"+ json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }


    // Get all Company data
    const getAllCompany = async (page, limit) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/company/show/?page=${page}&limit=${limit}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            const json = await response.json()
            setLoading(false);
            companyData(json);
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Handle Per Rows Change
    const perRowsChange = async (newPerPage, page) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/company/show/?page=${page}&limit=${newPerPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            setLoading(false);
            const json = await response.json()
            companyData(json);
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Delete a company
    const deleteCompany = async (id, page, limit) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/company/deletecompany/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            const json = await response.json()
            if (json.success) {
                setLoading(false);
                getAllCompany(page, limit);
            } else {
                console.log("Oops!"+ json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    return (
        <CompanyContext.Provider value={{ allCompany, loading, getAllCompany, deleteCompany, perRowsChange, addCompanyRecord, updateCompanyRecord }}>
            {props.children}
        </CompanyContext.Provider>
    )
}
export default CompanyState;