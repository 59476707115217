import React, { useState } from "react";
import CategoryContext from "./categoryContext";


const CategoryState = (props) => {
    const [allCategory, categoryData] = useState([])
    const [loading, setLoading] = useState(false);
    const host = process.env.REACT_APP_DB_HOST 
   
    // Get all Category data
    const getAllCategory = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/front-api/category/show`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json() 
            setLoading(false);
            
            categoryData(json);
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    return (
        <CategoryContext.Provider value={{ allCategory, loading, getAllCategory }}>
            {props.children}
        </CategoryContext.Provider>
    )
}
export default CategoryState;