import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loader from "./common/Loader";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Home from "./pages/Common/Home";
import DronePilots from "./pages/Drone/DronePilots";
import DroneLoans from "./pages/Drone/DroneLoans";
import DroneInsurance from "./pages/Drone/DroneInsurance";
import DroneJobs from "./pages/Drone/DroneJobs";
import DroneTenders from "./pages/Drone/DroneTenders";
import DroneParts from "./pages/Drone/Store/DroneParts";
import Drone from "./pages/Drone/Store/Drone";

// import { Header, Footer, Sidebar } from "./components/admin";

// New Services for Favara
import CategoryState from "./context/services/Category/CategoryState";

import PilotState from "./context/services/Pilot/PilotState";
// import Pilot from "./pages/Drone/Pilot/Pilot";
import CompanyState from "./context/services/Company/CompanyState";
// import Company from "./pages/Drone/Company/Company";
import JobState from "./context/services/Job/JobState";
// import Job from "./pages/Drone/Job/Job";
import TendorState from "./context/services/Tendor/TendorState";
// import Tendor from "./pages/Drone/Tendor/Tendor";
import StoreState from "./context/product/Store/StoreState";
// import Store from "./pages/Drone/Store/Store";

function App() {
  return (
    <div className="App">
      <>
        <CategoryState>
          <PilotState>
            <CompanyState>
              <JobState>
                <TendorState>
                  <StoreState>
                    <Router>
                      <Loader />
                      <div className="page-wraper">
                        <Header />
                        <Routes>
                          <Route path="/" exact element={<Home />} />

                          <Route
                            path="/drone-pilots"
                            exact
                            element={<DronePilots />}
                          />
                          <Route
                            path="/drone-loans"
                            exact
                            element={<DroneLoans />}
                          />

                          <Route
                            path="/drone-insurance"
                            exact
                            element={<DroneInsurance />}
                          />

                          <Route
                            path="/drone-jobs"
                            exact
                            element={<DroneJobs />}
                          />

                          <Route
                            path="/drone-tenders"
                            exact
                            element={<DroneTenders />}
                          />

                          <Route
                            path="/buy-drone"
                            exact
                            element={<Drone />}
                          />

                          <Route
                            path="/buy-drone-parts"
                            exact
                            element={<DroneParts />}
                          />

                        </Routes>
                        <Footer />
                      </div>
                    </Router>
                  </StoreState>
                </TendorState>
              </JobState>
            </CompanyState>
          </PilotState>
        </CategoryState>
      </>
    </div>
  );
}

export default App;
