import React, { useState } from "react";
import StoreContext from "./storeContext"; 
 
const StoreState = (props) => {
    const [allStore, storeData] = useState([])
    const [loading, setLoading] = useState(false);
    const host = process.env.REACT_APP_DB_HOST
    const token = localStorage.getItem('token');

    // Add Store Record
    const addStoreRecord = async (title, store, page, limit) => {
        try {
            // ** Do not pass json content type while uploading file in api
            const payload = new FormData();
            payload.append('store', store);
            payload.append('title', title);

            const response = await fetch(`${host}/api/product/category/add/`, {
                method: 'POST',
                headers: {
                    'auth-token': token
                },
                body: payload
            });
            const json = await response.json()
            if (json.success) { 
                getAllStore(page, limit)
            } else {
                console.log("Oops!"+ json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Update Store Record
    const updateStoreRecord = async (id, title, store, page, limit) => {
        try {
            // ** Do not pass json content type while uploading file in api
            const payload = new FormData();
            payload.append('store', store);
            payload.append('title', title);

            const response = await fetch(`${host}/api/product/category/updatestore/${id}`, {
                method: 'PUT',
                headers: {
                    'auth-token': token
                },
                body: payload
            });
            const json = await response.json()
            if (json.success) { 
                getAllStore(page, limit)
            } else {
                console.log("Oops!"+ json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }


    // Get all Store data
    const getAllStore = async (page, limit) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/product/category/show/?page=${page}&limit=${limit}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            const json = await response.json()
            setLoading(false);
            storeData(json);
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Handle Per Rows Change
    const perRowsChange = async (newPerPage, page) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/product/category/show/?page=${page}&limit=${newPerPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            setLoading(false);
            const json = await response.json()
            storeData(json);
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    // Delete a note
    const deleteStore = async (id, page, limit) => {
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/product/category/deletestore/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': token
                }
            });
            const json = await response.json()
            if (json.success) {
                setLoading(false);
                getAllStore(page, limit);
            } else {
                console.log("Oops!"+ json.error);
            }
        } catch (error) {
            console.log('Server Error : ' + error.message);
        }
    }

    return (
        <StoreContext.Provider value={{ allStore, loading, getAllStore, deleteStore, perRowsChange, addStoreRecord, updateStoreRecord }}>
            {props.children}
        </StoreContext.Provider>
    )
}
export default StoreState;