import React from "react";

const Loader = () => {
  return (
    <>
      <div className="loading-area">
        <div className="loading-box" ></div>
        <div className="loading-pic">
          <div className="wrapper">
            <div className="cssload-loader" ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
