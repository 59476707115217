import React from "react";
import Breadcrumb from "../../common/Breadcrumb";

const DroneTenders = () => {
  return (
    <>
      <div className="page-content">
        <Breadcrumb title="Drone Tenders" subtitle="Drone Tenders" />

        <div
          className="section-full p-t120  p-b90 site-bg-white bg-cover twm-ac-fresher-wrap"
          style={{
            backgroundImage: "url(assets/images/background/pattern.jpg)",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 m-b30">
                <div className="twm-jobs-grid-style1">
                  <div className="twm-media">
                    <img src="assets/images/jobs-company/pic1.jpg" alt="#" />
                  </div>
                  <span className="twm-job-post-duration">1 days ago</span>
                  <div className="twm-jobs-category green">
                    <span className="twm-bg-green">New</span>
                  </div>
                  <div className="twm-mid-content">
                    <a href="job-detail.html" className="twm-job-title">
                      <h4>Senior Web Designer , Developer</h4>
                    </a>
                    <p className="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      className="twm-job-websites site-text-primary"
                    >
                      https://thewebmax.com
                    </a>
                  </div>
                  <div className="twm-right-content">
                    <div className="twm-jobs-amount">
                      $2500 <span>/ Month</span>
                    </div>
                    <a
                      href="job-detail.html"
                      className="twm-jobs-browse site-text-primary"
                    >
                      View Job
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 m-b30">
                <div className="twm-jobs-grid-style1">
                  <div className="twm-media">
                    <img src="assets/images/jobs-company/pic1.jpg" alt="#" />
                  </div>
                  <span className="twm-job-post-duration">1 days ago</span>
                  <div className="twm-jobs-category green">
                    <span className="twm-bg-green">New</span>
                  </div>
                  <div className="twm-mid-content">
                    <a href="job-detail.html" className="twm-job-title">
                      <h4>Senior Web Designer , Developer</h4>
                    </a>
                    <p className="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      className="twm-job-websites site-text-primary"
                    >
                      https://thewebmax.com
                    </a>
                  </div>
                  <div className="twm-right-content">
                    <div className="twm-jobs-amount">
                      $2500 <span>/ Month</span>
                    </div>
                    <a
                      href="job-detail.html"
                      className="twm-jobs-browse site-text-primary"
                    >
                      View Job
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 m-b30">
                <div className="twm-jobs-grid-style1">
                  <div className="twm-media">
                    <img src="assets/images/jobs-company/pic1.jpg" alt="#" />
                  </div>
                  <span className="twm-job-post-duration">1 days ago</span>
                  <div className="twm-jobs-category green">
                    <span className="twm-bg-green">New</span>
                  </div>
                  <div className="twm-mid-content">
                    <a href="job-detail.html" className="twm-job-title">
                      <h4>Senior Web Designer , Developer</h4>
                    </a>
                    <p className="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      className="twm-job-websites site-text-primary"
                    >
                      https://thewebmax.com
                    </a>
                  </div>
                  <div className="twm-right-content">
                    <div className="twm-jobs-amount">
                      $2500 <span>/ Month</span>
                    </div>
                    <a
                      href="job-detail.html"
                      className="twm-jobs-browse site-text-primary"
                    >
                      View Job
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 m-b30">
                <div className="twm-jobs-grid-style1">
                  <div className="twm-media">
                    <img src="assets/images/jobs-company/pic1.jpg" alt="#" />
                  </div>
                  <span className="twm-job-post-duration">1 days ago</span>
                  <div className="twm-jobs-category green">
                    <span className="twm-bg-green">New</span>
                  </div>
                  <div className="twm-mid-content">
                    <a href="job-detail.html" className="twm-job-title">
                      <h4>Senior Web Designer , Developer</h4>
                    </a>
                    <p className="twm-job-address">
                      1363-1385 Sunset Blvd Los Angeles, CA 90026, USA
                    </p>
                    <a
                      href="https://themeforest.net/user/thewebmax/portfolio"
                      className="twm-job-websites site-text-primary"
                    >
                      https://thewebmax.com
                    </a>
                  </div>
                  <div className="twm-right-content">
                    <div className="twm-jobs-amount">
                      $2500 <span>/ Month</span>
                    </div>
                    <a
                      href="job-detail.html"
                      className="twm-jobs-browse site-text-primary"
                    >
                      View Job
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DroneTenders;
